import Article from './components/Articles';
import Categories from './components/Categories';
import Footer from "./components/Footer";
import Header from "./components/Header";
import NavigationBar from "./components/NavigationBar";

function Home() {
    return (
        <div className="d-flex flex-column" style={{ minHeight: '100vh' }}>

            <Header />

            <NavigationBar />

            <div className="flex-grow-1 py-3">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-3 col-xl-2">
                            <Categories />
                        </div>
                        <div className="col-lg-9 col-xl-10">
                            <section className="article">
                                <Article
                                    id="44"
                                    title="Kimi Raikonnen impressive drive to pole at the hungary grand prix"
                                    content="Sorry everyone, but from now on, I will only be able to edit and upload one design tutorial per month. This happens because I'm having a lot on my plate right now and recording and editing the tutorials requiere a lot of attention."
                                    featuredImage="images/relax.jpeg"
                                    createdAt="Aug 10, 2021"
                                />

                                <Article
                                    id="101"
                                    title="Paris Saint German (PSG) appear set to unveil former Barca striker Lionel Messi"
                                    content="Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquam quo ullam ut. Culpa ex harum cumque officia magni facere obcaecati atque tenetur officiis aliquid. Ad a ratione consectetur et atque!"
                                    featuredImage="images/peace.jpg"
                                    createdAt="Dec 10, 2020"
                                />
                                
                                <Article
                                    id="30"
                                    title="Miami heat guard Dwayne Wade scores 30 points in his final game"
                                    content="Here's a sneak peek of the official box cover art for Machine Wasteland II! Remember that I'll be having a stream showing a preview tommorrow at 9PM PCT!"
                                    featuredImage="images/vanquish.jpg"
                                    createdAt="Apr 9, 2019"
                                />
                            </section>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />

        </div>
    );
}

export default Home;
