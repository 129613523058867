function Categories() {
    return (
        <section className="category">
            <div className="sidebar p-4">
                <div className="sidebar-title text-center">
                    Category
                </div>
                <div className="sidebar-content">
                    <label id="101" htmlFor="category" tabIndex="3" className="button">
                        Business
                    </label>
                    <label id="33" htmlFor="category" tabIndex="3" className="button">
                        Health
                    </label>
                    <label id="11" htmlFor="category" tabIndex="3" className="button">
                        Technology
                    </label>
                    <label id="100" htmlFor="category" tabIndex="3" className="button">
                        Sports
                    </label>
                    <label id="404" htmlFor="category" tabIndex="3" className="button">
                        Uncategoried
                    </label>
                </div>
            </div>
        </section>
    );
}

export default Categories;
