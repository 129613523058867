function Header() {
    return (
        <header className="p-2">
            <section className="header">
                <div className="p-2 px-3 d-flex justify-content-between">
                    <div>6-26-115</div>
                    <div className="text-uppercase">
                        <label id="0" htmlFor="header" tabIndex="1" className="title d-flex align-items-center">
                            Menu Works 2.10
                        </label>
                    </div>
                    <div>12:13 AM</div>
                </div>
            </section>
        </header>
    );
}

export default Header;
