function NavigationBar() {
    return (
        <nav className="d-flex align-items-center">
            <div className="flex-grow-1 text-uppercase">
                <section className="menu">
                    <label id="1" htmlFor="menu" tabIndex="2" className="button active">
                        About us
                    </label>
                    <label id="2" htmlFor="menu" tabIndex="2" className="button">
                        Features
                    </label>
                    <label id="3" htmlFor="menu" tabIndex="2" className="button">
                        Blog
                    </label>
                    <label id="4" htmlFor="menu" tabIndex="2" className="button">
                        Contact
                    </label>
                </section>
            </div>
            <div>
                <label tabIndex="-1" htmlFor="search">
                    <input tabIndex="-1" type="text" placeholder="/ search" />
                </label>
            </div>
        </nav>
    );
}

export default NavigationBar;
