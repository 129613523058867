function Article(props) {
    const { id, title, content, featuredImage, createdAt } = props;

    return (
        <article>
            <label id={id} htmlFor="article" tabIndex="4" className="title d-flex align-items-center">
                <div className="flex-grow-1 m-0 text-uppercase">
                    {title}
                </div>
                <div className="text-uppercase">
                    <span>
                        {createdAt}
                    </span>
                </div>
            </label>
            <div className="content d-flex">
                <div className="flex-grow-1 px-2 pt-1">
                    <p>
                        {content}
                    </p>
                </div>
                <div>
                    <img src={featuredImage} alt="" />
                </div>
            </div>
        </article>
    );
}

export default Article;
