import React from 'react';
//
import Home from './Home';
import './App.css';

var state = {
    id: 0,
    itemIndex: 0,
    section: '',
};

var header = [
    { id: 0, title: 'Home' }
];

var menu = [
    { id: 0, title: 'About Us' },
    { id: 1, title: 'Features' },
    { id: 2, title: 'Blog' },
    { id: 3, title: 'Contact' }
];

var category = [
    { id: 101, title: 'Business' },
    { id: 33, title: 'Health' },
    { id: 11, title: 'Technology' },
    { id: 100, title: 'Sports' },
    { id: 404, title: 'Uncategorized' }
];

var article = [
    { id: 0, title: 'Kimi Raikonnen impressive drive to pole at the hungary grand prix' },
    { id: 1, title: 'Live! Love! and Write some code..' },
    { id: 2, title: 'Dwayne Wade in match up against throttle' }
];

var store = {
    article: article,
    category: category,
    header: header,
    menu: menu
};

function gotoSection(className) {
    
    var section = document.querySelector('section.' + className);
    var rows = section.querySelectorAll('label');

    var index = 0;

    for (var item of rows) {

        if (state.section !== className) {
            item.focus();
            updateState(index, item);
            break;
        }
        
        if (state.itemIndex < index) {
            item.focus();
            updateState(index, item);
            break;
        }

        index++;

    }

}

function gotoSectionLastItem(className, lastItem) {
    
    var section = document.querySelector('section.' + className);
    var rows = section.querySelectorAll('label');

    var index = 0;

    for (var item of rows) {

        const itemPosition = index + 1; // the item's position

        if (!lastItem && itemPosition === state.itemIndex) {
            item.focus();
            updateState(index, item);
        }
        
        if (lastItem && itemPosition === rows.length) {
            item.focus();
            updateState(index, item);
        }

        index++;

    }

}

function updateState(index, item) {
    var data = {};

    data.itemIndex = index;
    data.id = Number(item.id);
    data.section = item.htmlFor;

    state = {
        ...state,
        ...data
    };
}

function handleTabKey(event) {
    var id = event.target.id;
    var section = event.target.htmlFor;

    // console.log(store[table]);

    // update state
    state.id = id;
    state.section = section;
}

function handleEnterKey() {
    console.log(state);
}

function moveLeft() {
    switch (state.section) {
        case 'header':
            //
            break;
            
        case 'menu':
            if (state.itemIndex !== 0) {
                gotoSectionLastItem('menu');
            }
            break;

        case 'category':
            //
            break;

        case 'article':
            gotoSection('category');
            break;

        case 'footer':
            //
            break;
            
        default:
            gotoSection('header');
            break;
    }
}

function moveUp() {
    switch (state.section) {
        case 'menu':
            gotoSection('header');
            break;

        case 'category':
            if (state.itemIndex === 0) {
                gotoSection('menu'); // go to next section
            } else {
                gotoSectionLastItem('category', false);
            }
            break;

        case 'article':
            if (state.itemIndex === 0) {
                gotoSection('menu'); // go to next section
            } else {
                gotoSectionLastItem('article', false);
            }
            break;

        case 'footer':
            gotoSectionLastItem('article', true);
            break;
            
        default:
            gotoSection('header');
            break;
    }
}

function moveRight() {
    switch (state.section) {
        case 'header':
            //
            break;
            
        case 'menu':
            if (state.itemIndex < (store.menu.length - 1)) {
                gotoSection('menu');
            }
            break;

        case 'category':
            gotoSection('article');
            break;

        case 'article':
            //
            break;

        case 'footer':
            //
            break;
            
        default:
            gotoSection('header');
            break;
    }
}

function moveDown() {
    switch (state.section) {
        case 'header':
            gotoSection('menu');
            break;
            
        case 'menu':
            gotoSection('category');
            break;

        case 'category':
            if (state.itemIndex < (store.category.length - 1)) {
                gotoSection('category');
            } else {
                gotoSection('footer');
            }
            break;

        case 'article':
            if (state.itemIndex < (store.article.length - 1)) {
                gotoSection('article');
            } else {
                gotoSection('footer');
            }
            break;

        case 'footer':
            // gotoSection('header');
            break;
            
        default:
            gotoSection('header');
            break;
    }
}

function App() {
	React.useEffect(() => {
		document.addEventListener('keyup', (event) => {
			switch (event.which) {
				case 9:
					handleTabKey(event);
					break;
	
				case 13:
					handleEnterKey();
					break;
	
				case 37:
					moveLeft();
					break;
	
				case 38:
					moveUp();
					break;
		
				case 39:
					moveRight();
					break;
	
				case 40:
					moveDown();
					break;
		
				default:
					return;
			}
	
		});
	}, []);

	return <Home />;
}

export default App;
