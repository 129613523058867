function Footer() {
    return (
        <section className="footer d-flex justify-content-between">
            <div>
                Use the keypad keys to highlight a selection and press Enter.
            </div>
            <div>
                Copyright 2021 Powered by <label id="0" htmlFor="footer" tabIndex="5">Wordpress</label>
            </div>
        </section>
    );
}

export default Footer;
